<template>
  <v-container>
    <v-card flat>
      <v-card-title>Welcome</v-card-title>
      <v-card-text v-if="!ok">
        <component :is="view" :init="true" :submit="submit" @success="next" />
      </v-card-text>
      <v-card-text v-else class="py-5 text-center">
        <v-progress-circular color="amber" indeterminate />
      </v-card-text>
      <v-card-actions v-if="!ok">
        <v-spacer />
        <v-btn
          color="amber darken-3"
          dark
          depressed
          rounded
          @click="submit = true"
        >
          <v-icon v-if="view_end">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import Profile from "@/components/SettingProfile";
import DarkMode from "../components/SettingDarkMode.vue";

export default {
  name: "Initialize",
  data: () => ({
    ok: false,
    submit: false,
    view_index: 0,
    view_names: ["profile", "dark-mode"],
  }),
  computed: {
    view() {
      return this.view_names[this.view_index];
    },
    view_end() {
      return this.view_index >= this.view_names.length - 1;
    },
  },
  components: { Profile, DarkMode },
  methods: {
    next() {
      if (this.view_end) {
        this.ok = true;
        setTimeout(() => {
          this.$router.push("/");
        }, 3000);
      } else {
        this.view_index++;
        this.submit = false;
      }
    },
  },
};
</script>
